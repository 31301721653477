.bagButton {
    padding: 0.5em;
    background: white;
    color: black;
    border: 1px solid black;
    border-radius: 0.3em;
    cursor: pointer;
    margin: 0.5em;
    width: 2.2em;
    text-align: center;
}

.chosenBagButton {
    background: lightgreen;
}