* {
  margin: 0;
  padding: 0;
}

.App-header {
  margin-bottom: 1em;
  padding: 0.5em 1em;
  background-color: #282c34;
  color: white;
  display: flex;
}

.App-header h2 {
  flex: 1;
}

.App-header button {
  background: #eee;
  color: black;
  margin-left: 2em;
}

button {
  padding: .5em 2em;
  background: #282c34;
  color: white;
  border: 1px solid black;
  cursor: pointer;
}

.center {
  text-align: center;
}