.teamCards {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 0.5em;
}

.roundCompleteButton:disabled {
    background: darkgray;
    cursor: not-allowed;
}

.teamCard {
    margin: 0 .5em;
    padding: 0.5em;
    border: 1px solid #000;
    border-radius: 5px 3px 3px;
    box-shadow: 0 0 10px -5px;
    background: #ece2dd;
}

.teamCard section {
    display: flex;
    align-items: flex-start;
    justify-content: center;
}
